@import 'colors';
@import 'flags';
@import 'resolutions';
@import 'dot-animation';

@font-face {
  font-family: "din-alternate", sans-serif;
  src: url("../fonts/DINAlternate-Bold.ttf") format('truetype');
}

@font-face {
  font-family: "din-condense", sans-serif;
  src: url("../fonts/DIN-Bold.ttf") format('truetype');
}


.purple-color {
  color: #BD10E0 !important;
}

.modal-content{
  background-color: white !important;
}

$border: 2px solid $yellow;
.loader{
  border-left-color: #9b9b9b !important;
}
body {
  font-family: 'Helvetica', 'Arial', sans;
  font-size: 14px;
  margin-bottom: 122px; // height of nav
  transition: margin-bottom 0.2s ease-in-out;
  &.nav-hidden {
    margin-bottom: 0;
  }
}

p {
  margin: 0;
}

a,
a:hover,
a:active,
a:focus {
  color: $yellow;
  text-decoration: none;
}

h1 {
  font-weight: 100;
  font-size: 19px;
  letter-spacing: 0.8px;
  margin: 0;
  padding-top: 18px;
  color: $yellow;
}

header {
  height: 56px;
}

.products {
  margin-bottom: 36px;
  @include sm() {
    margin-bottom: 72px;
  }
}

.company-name {
  h1 {
    padding-bottom: 18px;
  }
}

.company-info {
  margin-top: -194px;

  :first-child > :not(.button-wrap) {
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
  }

  .company-info-text {
    * {
      text-shadow: 0px 0px 40px rgba(0, 0, 0, 1), 0px 0px 10px rgba(0, 0, 0, 0.6);
      white-space: nowrap;
    }

    *:after {
      content: attr(data-content);
      color: #fff;
      text-indent: unset;
      position: absolute;
      text-align: center;
      z-index: 9999;
      text-shadow: none;

      -ms-transform: translate(-100%, 0);
      -webkit-transform: translate(-100%, 0);
      transform: translate(-100%, 0);
    }

    p {
      margin: 2px;
    }

    h1 {
      margin-bottom: 20px;
    }
  }

  &.gone :first-child > :not(.button-wrap) {
    opacity: 0;
  }

  font-weight: 600 !important;
  color: white;

  .col-12 {
    padding: 0px !important;
  }

  .open {
    background-color: #00cc00;
  }

  .closed {
    background-color: #ff5050;
  }

  #openingStatus {
    display: block;
    padding: 5px;
    width: 100px;
    margin: 20px auto 0px auto;
    border-radius: 4px;

    -webkit-box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.3);
  }

  .company-info-title {
    color: white !important;
    font-weight: 600 !important;
    font-size: 30px;
  }

  .company-info-address {
    margin-top: 20px;
    span {
      padding: 0 12px;
    }
  }

  .button-wrap {
    background-color: $lighter-gray;
    margin-top: 26px;
    &.button-wrap-webshop {
      margin-top: 16px;
      a {
        display: inline-block;
        width: auto;
        color: $yellow;
        font-size: 18px;
        font-weight: bold;
        line-height: 46px;
        &::after {
          content: '';
          background-image: url('../img/cart.svg');
          background-repeat: no-repeat;
          display: inline-block;
          width: 32px;
          height: 32px;
          position: relative;
          top: 11px;
          left: 8px;
        }
      }
    }
  }

  .tab-btn,
  .tab-btn:active {
    width: 94px;
    outline: none;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    //padding: 0.52rem 1.34rem !important;
    @include sm() {
      width: 120px;
      margin: 0 12px;
    }

    span {
      color: $yellow;
      font-size: 11px;
    }

    &.glyphicon {
      font-size: 24px;
      line-height: 40px;
    }

    &.glyphicon {
      height: 40px;
      // width: 30px;
      background-position: center !important;
      background-size: 30px !important;
      background-repeat: no-repeat !important;

      &.clock {
        background-image: url('../img/clock.svg');

        span {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      &.earphone {
        background-image: url('../img/phone.svg');
        a {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      &.map-marker {
        background: url('../img/pin.svg');
        a {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      //background-size: cover !important;
    }
  }
}

.company-info-extra {
  background-color: $lighter-gray;
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease-out;
  position: relative;
  top: -2px;

  body.opening-hours-shown &,
  body.map-shown & {
    height: auto !important;
    border-top: 1px solid $light-gray;
  }

  .company-info-extra-inner {
    display: table;
    width: 100%;
  }

  .company-info-extra-opening-hours {
    display: none;
    text-align: center;
    vertical-align: middle;
    body.opening-hours-shown & {
      display: table-cell;
    }
    p {
      padding: 24px 0;
      display: inline-block;
      text-align: left;
      line-height: 1.7em;
    }
    span {
      min-width: 100px;
      display: inline-block;
    }
  }

  .company-info-extra-map {
    display: none;
    height: auto !important;
    background-position: center;
    background-size: cover;
    margin-top: 2px;
    body.map-shown & {
      display: block;
    }
  }
}

.product-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  margin-top: 24px;
  background-size: cover;
  background-color: #eeeeee;
  box-shadow: 0px 1px 12px 0px rgba(189, 189, 189, 1);
  // @LINK: https://blog.theodo.fr/2018/01/responsive-iframes-css-trick/
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    // weird hack for iOS Safari
    // @LINK: https://github.com/ampproject/amphtml/issues/11133
    height: 0;
    max-height: 100%;
    max-width: 100%;
    min-height: 100%;
    min-width: 100%;
    width: 0;
  }
  .product-wrap-iframe-overlay {
    position: relative;
    background: none;
    padding-bottom: 100%;
    z-index: 1;
    .no-touch & {
      display: none;
    }
  }
}

.product-container {
  margin: 50px;
  max-width: 800px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

footer {
  div.footer-inner {
    border-top: 1px solid lighten($light-gray, 6%);
    color: $light-gray;
    padding: 20px 0 18px;
    overflow: auto;

    a {
      color: darken($light-gray, 10%);
      transition: color 0.2s ease-out;
      &:hover {
        color: darken($light-gray, 20%);
      }
    }

    .float-right {
      width: 100%;
      @include sm() {
        width: auto;
      }
    }
  }
}

@media (min-width: 640px) {
  .container2 {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container2 {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container2 {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container2 {
    max-width: 1536px;
  }
}

.container2 {
  width: 100%;
}

.product-modal {
  display: none;
  position: fixed;
  z-index: 99999;

  @include xs {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.product-modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 95%;

  @include xs {
    min-width: 450px;
    padding: 15px;
  }

  @include xxs {
    min-width: 280px;
  }

  max-width: 570px;
  text-align: center;
}

.close {
  padding-top: 5px;
  opacity: 1;
  margin-bottom: 20px;
  float: left;
  font-size: 28px;
  font-weight: bold;
  color: white;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #ffb000;
  text-align: center;
  line-height: 34px;

  &:hover,
  &:focus {
    color: white;
    opacity: 0.8;
    text-decoration: none;
    cursor: pointer;
  }
}

.empty-search {
  text-align: center;
  margin: 50px;
  font-weight: 300;
}

@import 'cover';
@import 'nav';
@import 'product';
@import 'i18n';
@import '404';
@import 'screen-site';
@import 'main-product';
@import 'similar-shops';
